@import url("https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,200;0,400;0,500;0,700;1,200;1,400;1,500;1,700&display=swap");
@import "mixins";
@import "~@angular/cdk/overlay-prebuilt.css";

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100%;
}

html {
  font-family: $font-family-primary;
  font-size: $font-size-base;
  font-weight: $font-weight-normal;
  overflow: hidden;
}

body {
  background: $background-primary;
  background: linear-gradient(
    to right bottom,
    $background-primary,
    $background-secondary
  );
  color: $font-color-text;
  overflow-y: auto;

  @include app-scrollbar;
}

section + section {
  margin-top: $spacing-xl;
}

a {
  &,
  &:visited {
    color: $font-color-link;
    text-decoration: none;
  }

  &:hover,
  &:focus {
      color: $border-color-focus;
  }
}

h1 {
  font-size: $font-size-lg;
  color: $font-color-inverted;
  letter-spacing: 1px;
}

h1,
h2 {
  font-weight: $font-weight-bold;
  text-transform: uppercase;

  @media (max-width: $breakpoint-mobile-xs) {
    font-size: $font-size-lg;
  }
}

h3,
h4 {
  font-weight: $font-weight-semibold;
}

h5,
h6 {
  font-weight: $font-weight-thin;
}

label {
  display: inline-flex;
  text-align: center;
  align-items: center;
  justify-content: flex-start;
  padding: 0 $spacing-sm $spacing-sm;
  gap: $spacing-sm;
  text-transform: uppercase;
  font-weight: $font-weight-semibold;

  &.required,
  .required {
    &::after {
      content: '';
      display: inline-block;
      width: 5px;
      height: 5px;
      background: $color-error;
      border-radius: $border-radius-max;
      flex-shrink: 0;
    }
  }
}

fieldset {
  @include app-fieldset-base;
}

button,
a.btn {
  @include app-button-base;
}

table {
  width: 100%;
  border-spacing: 0 $spacing-sm;

  thead {
    th {
      font-weight: $font-weight-normal;
      font-size: $table-header-font-size;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      padding: 0 $spacing-xs;
      color: $font-color-text;

      @media (min-width: $breakpoint-mobile + 1) {
        padding: $spacing-sm;
      }
    }
  }

  tbody {
    tr {
      border-radius: $table-border-radius;
      box-shadow: 0 3px 4px 0 rgba(0,0,0,0.2);
    }
    td {
      min-height: 64px;
    }
    td, th {
      position: relative;
      height: $table-row-height;
      padding: $spacing-xs;

      @media (min-width: $breakpoint-mobile + 1) {
        padding: $spacing-sm;
      }

      &:first-child {
        border-radius: $table-border-radius 0 0 $table-border-radius;
      }

      &:last-child {
        border-radius: 0 $table-border-radius $table-border-radius 0;
      }

      &:first-child:last-child {
        border-radius: $table-border-radius;
      }
    }
  }

  td, th {
    &.left {
      text-align: left;
    }

    &.center {
      text-align: center;
    }

    &.right {
      text-align: right;
    }
  }

  tr.highlighted {
    td, th {
      background: $background-inverted;
    }
  }

  .indent {
    th {
      padding-left: $spacing-base;
    }

    th, td {
      background: rgba($background-tertiary, 0.2);
    }
  }
}

.icon-prefix {
  margin-right: $spacing-sm;
}

.icon-suffix {
  margin-left: $spacing-sm;
}

.hide-on-mobile-xs {
  @media (max-width: $breakpoint-mobile-xs) {
    display: none;
  }
}

.hide-on-mobile {
  @media (max-width: $breakpoint-mobile) {
    display: none;
  }
}

.hide-on-tablet {
  @media (max-width: $breakpoint-tablet) {
    display: none;
  }
}

.hide-on-desktop {
  @media (min-width: $breakpoint-mobile + 1) {
    display: none;
  }
}

.app__bg {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  bottom: -100px;
  right: 0;
  opacity: 0.3;
  z-index: -1;
  animation: slide-down 1.5s;

  > img {
    display: block;
    height: 100%;
    width: auto;
  }
}

.grecaptcha-badge {
  right: -100% !important;
  opacity: 0;
}

.copy-text {
  cursor: copy;
  color: $font-color-link;

  &:hover,
  &:focus {
    color: $border-color-focus;
  }
}

.submenu {
  display: flex;
  position: sticky;
  transform: translate(0);
  width: 100vw;
  z-index: 4;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0 4px 6px 0 rgba(0,0,0,50%);

  @media (max-width: $breakpoint-tablet) {
    > * {
      flex-direction: column;

      > fa-icon {
        margin-bottom: $spacing-xs;
      }

      > span {
        font-size: $font-size-xs;
        letter-spacing: 0.1px;
      }
    }
  }
  @media (min-width: $breakpoint-tablet + 1) {
    > * {
      padding: $spacing-base;

      > fa-icon {
        margin-right: $spacing-sm;
      }
    }
  }

  .active, .active:hover {
    color: $color-primary;
    background: $background-inverted;
  }

  > * {
    border-radius: 0;
    margin: 0;
    @include app-button-variant($font-color-inverted, $color-tertiary);

    &:not(:first-of-type,:last-of-type) {
      border-left: solid 1px rgba(0,0,0, 50%);
      border-right: solid 1px rgba(0,0,0, 50%);
    }
  }
}
