@import "variables";
@import "colors";
@import "animations";

@mixin app-scrollbar() {
  @media only screen and (hover: hover) and (pointer: fine) {
    & {
      scrollbar-width: thin;
      scrollbar-color: $background-secondary $background-tertiary;
    }

    &::-webkit-scrollbar {
      width: $scrollbar-size;
      height: $scrollbar-size;
      border-radius: $border-radius-xs;
      overflow: hidden;
    }

    &::-webkit-scrollbar-thumb {
      background: $background-secondary;
      width: $scrollbar-size;
      height: $scrollbar-size;
      border-radius: $border-radius-xs;
    }

    &::-webkit-scrollbar-track {
      background-color: $background-tertiary;
    }

    &::-webkit-scrollbar-button {
      width: 0;
      height: 0;
      opacity: 0;
    }
  }
}

@mixin app-button-base() {
  position: relative;
  cursor: pointer;
  font-family: $font-family-primary;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  border: none;
  background: inherit;
  font-size: $font-size-lg;
  color: inherit;
  border-bottom: solid $button-border-width rgba(0,0,0,0.3);
  line-height: 1;
  outline: 0;
  width: 100%;
  padding: $spacing-sm $spacing-base;
  font-weight: $font-weight-semibold;
  transition: background-color, transform 0.4s ease;
  border-radius: $border-radius;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);

  &.loading {
    position: relative;
    overflow: hidden;
    color: transparent !important;

    &::after {
      content: '';
      position: absolute;
      border-radius: 50%;
      border: solid 3px $color-inverted;
      border-bottom: none;
      width: 20px;
      height: 20px;
      animation: spin 1s infinite linear;
    }
  }

  @include app-button-variant($font-color-inverted, $color-primary);

  &.btn {
    &--sm {
      font-size: $font-size-md;
      height: $button-size-sm;
      border-radius: $border-radius-sm;
      padding: $spacing-xs $spacing-sm;

      @media (max-width: $breakpoint-mobile-xs) {
        font-size: $font-size-base;
        padding: $spacing-xs;
      }
    }

    &--md {
      font-size: $font-size-lg;
      height: $button-size-md;
      min-width: $button-size-md;
      border-radius: $border-radius-md;

      @media (max-width: $breakpoint-mobile-xs) {
        font-size: $font-size-base;
        padding: $spacing-sm;
      }
    }

    &--lg {
      font-size: $font-size-lg;
      height: $button-size-lg;
      min-width: $button-size-lg;
      border-radius: $border-radius-lg;

      @media (max-width: $breakpoint-mobile-xs) {
        font-size: $font-size-md;
        height: $button-size-md;
        min-width: $button-size-md;
        border-radius: $border-radius-md;
        padding: $spacing-sm;
      }
    }

    &--right {
      justify-content: right;
    }

    &--left {
      justify-content: left;
    }

    &--inverted {
      @include app-button-variant-inverted($color-primary, $font-color-inverted);
    }

    &--secondary {
      @include app-button-variant($color-tertiary, $color-secondary);
    }

    &--secondary-inverted {
      @include app-button-variant-inverted($color-secondary, $color-tertiary);
    }

    &--tertiary,
    &--active,
    &.active {
      @include app-button-variant($color-inverted, $color-tertiary);
    }

    &--tertiary-inverted {
      @include app-button-variant-inverted($color-tertiary, $color-inverted);
    }

    &--info {
      @include app-button-variant($color-tertiary, $color-info);
    }

    &--info-inverted {
      @include app-button-variant-inverted($color-info, $color-tertiary);
    }

    &--error {
      @include app-button-variant($color-inverted, $color-error);
    }

    &--error-inverted {
      @include app-button-variant-inverted($color-error, $color-inverted);
    }

    &--warning {
      @include app-button-variant($color-tertiary, $color-warning);
    }

    &--warning-inverted {
      @include app-button-variant-inverted($color-warning, $color-tertiary);
    }

    &--success {
      @include app-button-variant($color-tertiary, $color-success);
    }

    &--success-inverted {
      @include app-button-variant-inverted($color-success, $color-tertiary);
    }

    &--important {
      @include app-button-variant($color-tertiary, $color-important);
    }

    &--important-inverted {
      @include app-button-variant-inverted($color-important, $color-tertiary);
    }

    &--link {
      @include app-button-variant-transparent($font-color-link, $color-inverted);
    }

    &--link-inverted {
      @include app-button-variant-transparent($color-inverted, $font-color-link);
    }

    &--round {
      width: $button-size-xl;
      height: $button-size-xl;
      border-radius: $border-radius-max;
      border: solid 2px rgba(0 0 0 / 20%);
      box-shadow: 0 5px 5px rgb(0 0 0 / 30%);
      margin: auto;
      color: darken($color-secondary, 20%);
      font-size: $font-size-xl;
      text-shadow: 0 -1px 0px rgba(0 0 0 / 60%);
      background: linear-gradient(92.88deg, $color-secondary 9.16%, lighten($color-secondary, 10%) 43.89%, darken($color-secondary, 10%) 64.72%);

      &:disabled,
      &.disabled {
        color: $font-color-inactive;
        background: linear-gradient(92.88deg, #ccc 9.16%, #fff 43.89%, #ddd 64.72%);
        border-bottom-color: rgba(0 0 0 / 20%);
      }
    }

    &--header {
      height: 50px;
      border-radius: 0;
      font-size: $font-size-base;

      @media (min-width: $breakpoint-mobile) {
        height: 60px;
        font-size: $font-size-lg;
      }


      &:disabled {
        background: transparent;
        border-color: transparent;
        color: $font-color-text;
      }
    }
  }

  &:focus,
  &:focus-within,
  &:active,
  &.active {
    z-index: 1;
    border-bottom-color: transparent;
  }

  &:disabled,
  &.disabled {
    cursor: not-allowed;
    border-bottom-color: transparent;
  }
}

@mixin app-button-variant($text-color, $background-color) {
  $button-bg: $background-color;
  $button-bg-hover: lighten($button-bg, 15%);
  background: $button-bg;
  background: linear-gradient(120deg, $button-bg 0%, darken($button-bg, 5%) 50%, $button-bg 100%);
  color: $text-color;

  &:hover:not(:disabled, .disabled, .radio__button--checked, .active) {
    background: $button-bg-hover;
  }

  &:disabled,
  &.disabled {
    &:not(.app-button--active, .radio__button--checked, .active) {
      color: $font-color-text;
      background: $font-color-inactive;
    }
  }

  &.loading {
    &::after {
      border-color: $font-color-text;
    }
  }
}

@mixin app-button-variant-inverted($background-color, $text-color) {
  $button-color: $background-color;
  $button-color-hover: lighten($button-color, 15%);
  background: transparent;
  color: $button-color;
  border-bottom: none;
  box-shadow: none;

  &:hover:not(:disabled, .disabled, .active) {
    color: $text-color;
    background: $button-color-hover;
  }

  &:disabled,
  &.disabled {
    &:not(.app-button--active, .radio__button--checked, .active) {
      color: $font-color-text;
      background: $font-color-inactive;
    }
  }

  &.loading {
    &::after {
      border-color: $font-color-text;
    }
  }
}

@mixin app-button-variant-transparent($text-color, $invert-text-color) {
  background: transparent;
  color: $text-color;
  border: none;
  box-shadow: none;

  &:hover:not(:disabled, .disabled, .active, .loading) {
    color: lighten($text-color, 10%);
    background: transparent;
  }

  &:disabled,
  &.disabled {
    &:not(.app-button--active, .radio__button--checked) {
      color: $font-color-inactive;
      background: transparent;
    }
  }

  &.loading {
    &::after {
      border-color: $font-color-text;
    }
  }
}

@mixin app-input-base() {
  position: relative;
  z-index: 1;
  display: block;
  flex-grow: 0;
  width: 100%;
  font-size: $input-font-size;
  font-family: $font-family-primary;
  font-weight: $font-weight-thin;
  line-height: 1;
  color: $font-color-text;
  padding: $spacing-xs $spacing-sm;
  background: rgba($background-inverted, 0.8);
  border-radius: $border-radius;
  border: solid $button-border-width $font-color-text;
  outline: 0;
  transition: border-color 0.4s ease;
  height: $input-height;

  &,
  & input {
    appearance: none;
    -webkit-appearance: none;
  }

  &.md,
  & .md {
    height: $button-size-md;
    border-radius: $border-radius-md;
    padding: $spacing-xs $spacing-sm;
    font-size: $font-size-base;
  }

  &.sm,
  & .sm {
    height: $button-size-sm;
    border-radius: $border-radius-sm;
    padding: $spacing-xs $spacing-sm;
    font-size: $font-size-md;
  }

  &:-webkit-autofill,
  & :-webkit-autofill {
    &,
    &:focus,
    &:hover {
      -webkit-text-fill-color: $font-color-text;
      background-color: transparent;
      transition: background-color 5000s ease-in-out 0s;
      font-size: $input-font-size;
    }
  }

  &.filled,
  & .filled {
    &,
    &:-webkit-autofill,
    & :-webkit-autofill {
      border-color: $border-color-filled;
    }
  }

  &:focus,
  &:focus-within,
  & :focus {
    &,
    &:-webkit-autofill,
    & :-webkit-autofill {
      border-color: $border-color-focus;
    }
  }

  &:hover:not(:disabled),
  &:hover:not(:has(:disabled)) {
    &,
    &:-webkit-autofill,
    & :-webkit-autofill {
      box-shadow: 0 1px 2px 0 rgba(0,0,0,0.1);
    }
  }

  &:disabled,
  &:has(:disabled) {
    &,
    & :disabled {
      cursor: not-allowed;
    }
    border-color: rgba($font-color-text, 0.5);
    background-color: rgba($background-inverted, 0.5) !important;
  }

  &[type="number"]:read-only,
  &[type="text"]:read-only,
  &[type="tel"]:read-only,
  &[type="email"]:read-only,
  &[type="password"]:read-only,
  &[type="date"]:read-only,
  &[type="datetime-local"]:read-only,
  &[type="time"]:read-only,
  &[type="url"]:read-only,
  &:has(input:read-only) {
    border-color: rgba($font-color-text, 0.5);
    background-color: rgba($background-inverted, 0.5) !important;
  }

  &[type="number"],
  & [type="number"] {
    -moz-appearance: textfield;
  }

  &::placeholder,
  & ::placeholder {
    color: $font-color-text;
    font-weight: $font-weight-thin;
  }


  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button,
  & ::-webkit-outer-spin-button,
  & ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@mixin app-fieldset-base() {
  position: relative;
  border: solid 3px transparent;
  margin: 0 0 $spacing-base 0;
  border-radius: $border-radius-lg;
  background: rgba($background-inverted, 0.7);
  box-shadow: 0 3px 4px rgba(0,0,0,0.3);

  legend {
    display: flex;
    align-items: center;
    font-size: $font-size-base;
    text-transform: uppercase;
    font-weight: $font-weight-semibold;
    padding: $spacing-sm $spacing-base;
    transition: color 0.2s;
    text-align: left;
    margin: auto;
    border-radius: $border-radius;
    background-color: $border-color-primary;
    color: $font-color-inverted;
    box-shadow: 0 3px 2px rgba(0,0,0,0.2);

    small {
      font-size: $font-size-md;
    }
  }

  .fieldset__info {
    text-align: left;
    background: $background-info;
    color: $font-color-inverted;
    padding: $spacing-sm;
    border-radius: $border-radius;
    line-height: 1.4;
  }

  .fieldset__result {
    display: block;
    white-space: nowrap;
    line-height: $input-height;
    margin-bottom: $spacing-base;
    font-size: $font-size-lg;
    color: $color-info;
    padding: 0 $spacing-xs;
  }

  .fieldset__divider {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: $spacing-xl $spacing-sm;
    padding: 0;
    text-transform: uppercase;

    &::before,
    &::after {
      content: '';
      display: block;
      margin: 0 $spacing-sm;
      background: $font-color-text;
      height: 1px;
      flex-shrink: 0;
      flex-grow: 1;
    }
  }

  &.warning {
    @include app-fieldset-variant($color-warning, $font-color-text);
  }

  &.success {
    @include app-fieldset-variant($color-success, $font-color-text);
  }

  &.error {
    @include app-fieldset-variant($color-error, $font-color-inverted);
  }

  &:focus-within {
    > legend {
      color: $border-color-focus;
    }
  }
}

@mixin app-fieldset-variant($border-color, $text-color) {
  // background-color: lighten($border-color, 50%);

  legend {
    color: $text-color;
    background-color: $border-color;
  }
}

@mixin app-player-class-bg($color) {
  $color-end: darken($color, 10%);
  background: linear-gradient(
      110deg,
      transparent 30.5%,
      rgba($color, 50%) 32%,
      $color 33.5%,
      $color-end
  );
}

@mixin app-player-class() {
  &--S {
    @include app-player-class-bg($class-s);
  }

  &--A {
    @include app-player-class-bg($class-a);
  }

  &--B {
    @include app-player-class-bg($class-b);
  }

  &--C {
    @include app-player-class-bg($class-c);
  }

  &--D {
    @include app-player-class-bg($class-d);
  }

  &--X {
    @include app-player-class-bg($class-x);
  }
}

@mixin app-player-position-bg($color) {
  $color-end: darken($color, 5%);
  background: linear-gradient(
      110deg,
      $color,
      $color-end
  ),
}

@mixin app-player-position() {
  &--offense {
    @include app-player-position-bg($position-offense);
  }

  &--midfield {
    @include app-player-position-bg($position-midfield);
  }

  &--defense {
    @include app-player-position-bg($position-defense);
  }

  &--goalKeeper {
    @include app-player-position-bg($position-goalKeeper);
  }

  &--free {
    @include app-player-position-bg($position-free);
  }
}

@mixin app-team-color() {
  &--contour {
    position: absolute;
    z-index: 0;
    color: $font-color-text;
  }

  &--red {
    color: $team-red;
  }

  &--black {
    color: $team-black;
  }

  &--white {
    color: $team-white;
  }
}
