$color-primary: #003a00;
$color-secondary: #e93a3a;
$color-tertiary: #1D1921;
$color-important: #AE61A1;
$color-inverted: #EFEFEF;
$color-info: #047E9B;
$color-error: #822802;
$color-warning: #cb7037;
$color-success: #A1C83F;
$color-inactive: #999999;
$color-gold: #FFBF00;
$color-silver: #A2B3B7;
$color-bronze: #D4853A;

$background-primary: #055c05;
$background-secondary: #048704;
$background-tertiary: #023402;
$background-hollow: #89BD9B;
$background-inverted: #ffffff;
$background-info: $color-info;
$background-error: $color-error;
$background-warning: $color-warning;
$background-success: $color-success;
$background-inactive: $color-inactive;

$border-color-primary: $background-tertiary;
$border-color-secondary: $background-inverted;
$border-color-focus: $color-warning;
$border-color-filled: $color-primary;
$border-color-error: $color-error;
$border-color-info: $color-info;

$font-color-link: $color-secondary;
$font-color-highlight: $color-primary;
$font-color-text: $color-tertiary;
$font-color-inverted: $color-inverted;
$font-color-tertiary: $color-important;
$font-color-inactive: $color-inactive;

$team-red: #d32a2a;
$team-black: #020202;
$team-white: #efefef;

$class-s: #cf5703;
$class-a: #9803e5;
$class-b: #2062dd;
$class-c: #01ac37;
$class-d: #72716b;
$class-x: #000000;

$position-offense: #94003b;
$position-midfield: #8c7400;
$position-defense: #048100;
$position-goalKeeper: #008294;
$position-free: #000000;

$leg-left: #008ea7;
$leg-right: #a75400;
$leg-both: #b70e90;
