@import url("https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,200;0,400;0,500;0,700;1,200;1,400;1,500;1,700&display=swap");
@import "~@angular/cdk/overlay-prebuilt.css";
@keyframes slide-up {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slide-down {
  0% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slide-out-right {
  0% {
    transform: translateX(0) rotateZ(0deg);
  }
  100% {
    transform: translateX(400px) rotateZ(45deg);
  }
}
@keyframes slide-out-left {
  0% {
    transform: translateX(0) rotateZ(0deg);
  }
  100% {
    transform: translateX(-400px) rotateZ(-45deg);
  }
}
@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes disappear {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes stamp {
  0% {
    transform: perspective(500px) translate3d(0, 0, 300px) rotate(-30deg);
  }
  100% {
    transform: perspective(500px) translate3d(0, 0, 0px) rotate(-30deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(1turn);
  }
}
@keyframes bounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes blink {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}
@keyframes roll-down-previous {
  0% {
    opacity: 1;
    transform: translateY(var(--counter-size, -20px)) rotateX(0);
  }
  100% {
    opacity: 0.7;
    transform: translateY(0) rotateX(60deg);
  }
}
@keyframes roll-down-current {
  0% {
    opacity: 0.7;
    transform: translateY(var(--counter-size, -20px)) rotateX(-60deg);
  }
  100% {
    opacity: 1;
    transform: translateY(0) rotateX(0);
  }
}
@keyframes roll-down-next {
  0% {
    opacity: 0;
    transform: translateY(var(--counter-size, -20px)) rotateX(-90deg);
  }
  100% {
    opacity: 0.7;
    transform: translateY(0) rotateX(-60deg);
  }
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100%;
}

html {
  font-family: "Exo 2", Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  overflow: hidden;
}

body {
  background: #055c05;
  background: linear-gradient(to right bottom, #055c05, #048704);
  color: #1D1921;
  overflow-y: auto;
}
@media only screen and (hover: hover) and (pointer: fine) {
  body {
    scrollbar-width: thin;
    scrollbar-color: #048704 #023402;
  }
  body::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 2px;
    overflow: hidden;
  }
  body::-webkit-scrollbar-thumb {
    background: #048704;
    width: 8px;
    height: 8px;
    border-radius: 2px;
  }
  body::-webkit-scrollbar-track {
    background-color: #023402;
  }
  body::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    opacity: 0;
  }
}

section + section {
  margin-top: 32px;
}

a, a:visited {
  color: #e93a3a;
  text-decoration: none;
}
a:hover, a:focus {
  color: #cb7037;
}

h1 {
  font-size: 20px;
  color: #EFEFEF;
  letter-spacing: 1px;
}

h1,
h2 {
  font-weight: 700;
  text-transform: uppercase;
}
@media (max-width: 320px) {
  h1,
  h2 {
    font-size: 20px;
  }
}

h3,
h4 {
  font-weight: 500;
}

h5,
h6 {
  font-weight: 200;
}

label {
  display: inline-flex;
  text-align: center;
  align-items: center;
  justify-content: flex-start;
  padding: 0 8px 8px;
  gap: 8px;
  text-transform: uppercase;
  font-weight: 500;
}
label.required::after,
label .required::after {
  content: "";
  display: inline-block;
  width: 5px;
  height: 5px;
  background: #822802;
  border-radius: 50%;
  flex-shrink: 0;
}

fieldset {
  position: relative;
  border: solid 3px transparent;
  margin: 0 0 16px 0;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.3);
}
fieldset legend {
  display: flex;
  align-items: center;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
  padding: 8px 16px;
  transition: color 0.2s;
  text-align: left;
  margin: auto;
  border-radius: 8px;
  background-color: #023402;
  color: #EFEFEF;
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.2);
}
fieldset legend small {
  font-size: 14px;
}
fieldset .fieldset__info {
  text-align: left;
  background: #047E9B;
  color: #EFEFEF;
  padding: 8px;
  border-radius: 8px;
  line-height: 1.4;
}
fieldset .fieldset__result {
  display: block;
  white-space: nowrap;
  line-height: 42px;
  margin-bottom: 16px;
  font-size: 20px;
  color: #047E9B;
  padding: 0 4px;
}
fieldset .fieldset__divider {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 32px 8px;
  padding: 0;
  text-transform: uppercase;
}
fieldset .fieldset__divider::before, fieldset .fieldset__divider::after {
  content: "";
  display: block;
  margin: 0 8px;
  background: #1D1921;
  height: 1px;
  flex-shrink: 0;
  flex-grow: 1;
}
fieldset.warning legend {
  color: #1D1921;
  background-color: #cb7037;
}
fieldset.success legend {
  color: #1D1921;
  background-color: #A1C83F;
}
fieldset.error legend {
  color: #EFEFEF;
  background-color: #822802;
}
fieldset:focus-within > legend {
  color: #cb7037;
}

button,
a.btn {
  position: relative;
  cursor: pointer;
  font-family: "Exo 2", Arial, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  border: none;
  background: inherit;
  font-size: 20px;
  color: inherit;
  border-bottom: solid 2px rgba(0, 0, 0, 0.3);
  line-height: 1;
  outline: 0;
  width: 100%;
  padding: 8px 16px;
  font-weight: 500;
  transition: background-color, transform 0.4s ease;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  background: #003a00;
  background: linear-gradient(120deg, #003a00 0%, #002100 50%, #003a00 100%);
  color: #EFEFEF;
}
button.loading,
a.btn.loading {
  position: relative;
  overflow: hidden;
  color: transparent !important;
}
button.loading::after,
a.btn.loading::after {
  content: "";
  position: absolute;
  border-radius: 50%;
  border: solid 3px #EFEFEF;
  border-bottom: none;
  width: 20px;
  height: 20px;
  animation: spin 1s infinite linear;
}
button:hover:not(:disabled, .disabled, .radio__button--checked, .active),
a.btn:hover:not(:disabled, .disabled, .radio__button--checked, .active) {
  background: #008700;
}
button:disabled:not(.app-button--active, .radio__button--checked, .active), button.disabled:not(.app-button--active, .radio__button--checked, .active),
a.btn:disabled:not(.app-button--active, .radio__button--checked, .active),
a.btn.disabled:not(.app-button--active, .radio__button--checked, .active) {
  color: #1D1921;
  background: #999999;
}
button.loading::after,
a.btn.loading::after {
  border-color: #1D1921;
}
button.btn--sm,
a.btn.btn--sm {
  font-size: 14px;
  height: 24px;
  border-radius: 4px;
  padding: 4px 8px;
}
@media (max-width: 320px) {
  button.btn--sm,
  a.btn.btn--sm {
    font-size: 16px;
    padding: 4px;
  }
}
button.btn--md,
a.btn.btn--md {
  font-size: 20px;
  height: 32px;
  min-width: 32px;
  border-radius: 6px;
}
@media (max-width: 320px) {
  button.btn--md,
  a.btn.btn--md {
    font-size: 16px;
    padding: 8px;
  }
}
button.btn--lg,
a.btn.btn--lg {
  font-size: 20px;
  height: 52px;
  min-width: 52px;
  border-radius: 10px;
}
@media (max-width: 320px) {
  button.btn--lg,
  a.btn.btn--lg {
    font-size: 14px;
    height: 32px;
    min-width: 32px;
    border-radius: 6px;
    padding: 8px;
  }
}
button.btn--right,
a.btn.btn--right {
  justify-content: right;
}
button.btn--left,
a.btn.btn--left {
  justify-content: left;
}
button.btn--inverted,
a.btn.btn--inverted {
  background: transparent;
  color: #003a00;
  border-bottom: none;
  box-shadow: none;
}
button.btn--inverted:hover:not(:disabled, .disabled, .active),
a.btn.btn--inverted:hover:not(:disabled, .disabled, .active) {
  color: #EFEFEF;
  background: #008700;
}
button.btn--inverted:disabled:not(.app-button--active, .radio__button--checked, .active), button.btn--inverted.disabled:not(.app-button--active, .radio__button--checked, .active),
a.btn.btn--inverted:disabled:not(.app-button--active, .radio__button--checked, .active),
a.btn.btn--inverted.disabled:not(.app-button--active, .radio__button--checked, .active) {
  color: #1D1921;
  background: #999999;
}
button.btn--inverted.loading::after,
a.btn.btn--inverted.loading::after {
  border-color: #1D1921;
}
button.btn--secondary,
a.btn.btn--secondary {
  background: #e93a3a;
  background: linear-gradient(120deg, #e93a3a 0%, #e62323 50%, #e93a3a 100%);
  color: #1D1921;
}
button.btn--secondary:hover:not(:disabled, .disabled, .radio__button--checked, .active),
a.btn.btn--secondary:hover:not(:disabled, .disabled, .radio__button--checked, .active) {
  background: #f17f7f;
}
button.btn--secondary:disabled:not(.app-button--active, .radio__button--checked, .active), button.btn--secondary.disabled:not(.app-button--active, .radio__button--checked, .active),
a.btn.btn--secondary:disabled:not(.app-button--active, .radio__button--checked, .active),
a.btn.btn--secondary.disabled:not(.app-button--active, .radio__button--checked, .active) {
  color: #1D1921;
  background: #999999;
}
button.btn--secondary.loading::after,
a.btn.btn--secondary.loading::after {
  border-color: #1D1921;
}
button.btn--secondary-inverted,
a.btn.btn--secondary-inverted {
  background: transparent;
  color: #e93a3a;
  border-bottom: none;
  box-shadow: none;
}
button.btn--secondary-inverted:hover:not(:disabled, .disabled, .active),
a.btn.btn--secondary-inverted:hover:not(:disabled, .disabled, .active) {
  color: #1D1921;
  background: #f17f7f;
}
button.btn--secondary-inverted:disabled:not(.app-button--active, .radio__button--checked, .active), button.btn--secondary-inverted.disabled:not(.app-button--active, .radio__button--checked, .active),
a.btn.btn--secondary-inverted:disabled:not(.app-button--active, .radio__button--checked, .active),
a.btn.btn--secondary-inverted.disabled:not(.app-button--active, .radio__button--checked, .active) {
  color: #1D1921;
  background: #999999;
}
button.btn--secondary-inverted.loading::after,
a.btn.btn--secondary-inverted.loading::after {
  border-color: #1D1921;
}
button.btn--tertiary, button.btn--active, button.btn.active,
a.btn.btn--tertiary,
a.btn.btn--active,
a.btn.btn.active {
  background: #1D1921;
  background: linear-gradient(120deg, #1D1921 0%, #100e12 50%, #1D1921 100%);
  color: #EFEFEF;
}
button.btn--tertiary:hover:not(:disabled, .disabled, .radio__button--checked, .active), button.btn--active:hover:not(:disabled, .disabled, .radio__button--checked, .active), button.btn.active:hover:not(:disabled, .disabled, .radio__button--checked, .active),
a.btn.btn--tertiary:hover:not(:disabled, .disabled, .radio__button--checked, .active),
a.btn.btn--active:hover:not(:disabled, .disabled, .radio__button--checked, .active),
a.btn.btn.active:hover:not(:disabled, .disabled, .radio__button--checked, .active) {
  background: #433a4d;
}
button.btn--tertiary:disabled:not(.app-button--active, .radio__button--checked, .active), button.btn--tertiary.disabled:not(.app-button--active, .radio__button--checked, .active), button.btn--active:disabled:not(.app-button--active, .radio__button--checked, .active), button.btn--active.disabled:not(.app-button--active, .radio__button--checked, .active), button.btn.active:disabled:not(.app-button--active, .radio__button--checked, .active), button.btn.active.disabled:not(.app-button--active, .radio__button--checked, .active),
a.btn.btn--tertiary:disabled:not(.app-button--active, .radio__button--checked, .active),
a.btn.btn--tertiary.disabled:not(.app-button--active, .radio__button--checked, .active),
a.btn.btn--active:disabled:not(.app-button--active, .radio__button--checked, .active),
a.btn.btn--active.disabled:not(.app-button--active, .radio__button--checked, .active),
a.btn.btn.active:disabled:not(.app-button--active, .radio__button--checked, .active),
a.btn.btn.active.disabled:not(.app-button--active, .radio__button--checked, .active) {
  color: #1D1921;
  background: #999999;
}
button.btn--tertiary.loading::after, button.btn--active.loading::after, button.btn.active.loading::after,
a.btn.btn--tertiary.loading::after,
a.btn.btn--active.loading::after,
a.btn.btn.active.loading::after {
  border-color: #1D1921;
}
button.btn--tertiary-inverted,
a.btn.btn--tertiary-inverted {
  background: transparent;
  color: #1D1921;
  border-bottom: none;
  box-shadow: none;
}
button.btn--tertiary-inverted:hover:not(:disabled, .disabled, .active),
a.btn.btn--tertiary-inverted:hover:not(:disabled, .disabled, .active) {
  color: #EFEFEF;
  background: #433a4d;
}
button.btn--tertiary-inverted:disabled:not(.app-button--active, .radio__button--checked, .active), button.btn--tertiary-inverted.disabled:not(.app-button--active, .radio__button--checked, .active),
a.btn.btn--tertiary-inverted:disabled:not(.app-button--active, .radio__button--checked, .active),
a.btn.btn--tertiary-inverted.disabled:not(.app-button--active, .radio__button--checked, .active) {
  color: #1D1921;
  background: #999999;
}
button.btn--tertiary-inverted.loading::after,
a.btn.btn--tertiary-inverted.loading::after {
  border-color: #1D1921;
}
button.btn--info,
a.btn.btn--info {
  background: #047E9B;
  background: linear-gradient(120deg, #047E9B 0%, #036a82 50%, #047E9B 100%);
  color: #1D1921;
}
button.btn--info:hover:not(:disabled, .disabled, .radio__button--checked, .active),
a.btn.btn--info:hover:not(:disabled, .disabled, .radio__button--checked, .active) {
  background: #06bbe6;
}
button.btn--info:disabled:not(.app-button--active, .radio__button--checked, .active), button.btn--info.disabled:not(.app-button--active, .radio__button--checked, .active),
a.btn.btn--info:disabled:not(.app-button--active, .radio__button--checked, .active),
a.btn.btn--info.disabled:not(.app-button--active, .radio__button--checked, .active) {
  color: #1D1921;
  background: #999999;
}
button.btn--info.loading::after,
a.btn.btn--info.loading::after {
  border-color: #1D1921;
}
button.btn--info-inverted,
a.btn.btn--info-inverted {
  background: transparent;
  color: #047E9B;
  border-bottom: none;
  box-shadow: none;
}
button.btn--info-inverted:hover:not(:disabled, .disabled, .active),
a.btn.btn--info-inverted:hover:not(:disabled, .disabled, .active) {
  color: #1D1921;
  background: #06bbe6;
}
button.btn--info-inverted:disabled:not(.app-button--active, .radio__button--checked, .active), button.btn--info-inverted.disabled:not(.app-button--active, .radio__button--checked, .active),
a.btn.btn--info-inverted:disabled:not(.app-button--active, .radio__button--checked, .active),
a.btn.btn--info-inverted.disabled:not(.app-button--active, .radio__button--checked, .active) {
  color: #1D1921;
  background: #999999;
}
button.btn--info-inverted.loading::after,
a.btn.btn--info-inverted.loading::after {
  border-color: #1D1921;
}
button.btn--error,
a.btn.btn--error {
  background: #822802;
  background: linear-gradient(120deg, #822802 0%, #692002 50%, #822802 100%);
  color: #EFEFEF;
}
button.btn--error:hover:not(:disabled, .disabled, .radio__button--checked, .active),
a.btn.btn--error:hover:not(:disabled, .disabled, .radio__button--checked, .active) {
  background: #cd3f03;
}
button.btn--error:disabled:not(.app-button--active, .radio__button--checked, .active), button.btn--error.disabled:not(.app-button--active, .radio__button--checked, .active),
a.btn.btn--error:disabled:not(.app-button--active, .radio__button--checked, .active),
a.btn.btn--error.disabled:not(.app-button--active, .radio__button--checked, .active) {
  color: #1D1921;
  background: #999999;
}
button.btn--error.loading::after,
a.btn.btn--error.loading::after {
  border-color: #1D1921;
}
button.btn--error-inverted,
a.btn.btn--error-inverted {
  background: transparent;
  color: #822802;
  border-bottom: none;
  box-shadow: none;
}
button.btn--error-inverted:hover:not(:disabled, .disabled, .active),
a.btn.btn--error-inverted:hover:not(:disabled, .disabled, .active) {
  color: #EFEFEF;
  background: #cd3f03;
}
button.btn--error-inverted:disabled:not(.app-button--active, .radio__button--checked, .active), button.btn--error-inverted.disabled:not(.app-button--active, .radio__button--checked, .active),
a.btn.btn--error-inverted:disabled:not(.app-button--active, .radio__button--checked, .active),
a.btn.btn--error-inverted.disabled:not(.app-button--active, .radio__button--checked, .active) {
  color: #1D1921;
  background: #999999;
}
button.btn--error-inverted.loading::after,
a.btn.btn--error-inverted.loading::after {
  border-color: #1D1921;
}
button.btn--warning,
a.btn.btn--warning {
  background: #cb7037;
  background: linear-gradient(120deg, #cb7037 0%, #b96530 50%, #cb7037 100%);
  color: #1D1921;
}
button.btn--warning:hover:not(:disabled, .disabled, .radio__button--checked, .active),
a.btn.btn--warning:hover:not(:disabled, .disabled, .radio__button--checked, .active) {
  background: #db9b74;
}
button.btn--warning:disabled:not(.app-button--active, .radio__button--checked, .active), button.btn--warning.disabled:not(.app-button--active, .radio__button--checked, .active),
a.btn.btn--warning:disabled:not(.app-button--active, .radio__button--checked, .active),
a.btn.btn--warning.disabled:not(.app-button--active, .radio__button--checked, .active) {
  color: #1D1921;
  background: #999999;
}
button.btn--warning.loading::after,
a.btn.btn--warning.loading::after {
  border-color: #1D1921;
}
button.btn--warning-inverted,
a.btn.btn--warning-inverted {
  background: transparent;
  color: #cb7037;
  border-bottom: none;
  box-shadow: none;
}
button.btn--warning-inverted:hover:not(:disabled, .disabled, .active),
a.btn.btn--warning-inverted:hover:not(:disabled, .disabled, .active) {
  color: #1D1921;
  background: #db9b74;
}
button.btn--warning-inverted:disabled:not(.app-button--active, .radio__button--checked, .active), button.btn--warning-inverted.disabled:not(.app-button--active, .radio__button--checked, .active),
a.btn.btn--warning-inverted:disabled:not(.app-button--active, .radio__button--checked, .active),
a.btn.btn--warning-inverted.disabled:not(.app-button--active, .radio__button--checked, .active) {
  color: #1D1921;
  background: #999999;
}
button.btn--warning-inverted.loading::after,
a.btn.btn--warning-inverted.loading::after {
  border-color: #1D1921;
}
button.btn--success,
a.btn.btn--success {
  background: #A1C83F;
  background: linear-gradient(120deg, #A1C83F 0%, #93b935 50%, #A1C83F 100%);
  color: #1D1921;
}
button.btn--success:hover:not(:disabled, .disabled, .radio__button--checked, .active),
a.btn.btn--success:hover:not(:disabled, .disabled, .radio__button--checked, .active) {
  background: #bed97a;
}
button.btn--success:disabled:not(.app-button--active, .radio__button--checked, .active), button.btn--success.disabled:not(.app-button--active, .radio__button--checked, .active),
a.btn.btn--success:disabled:not(.app-button--active, .radio__button--checked, .active),
a.btn.btn--success.disabled:not(.app-button--active, .radio__button--checked, .active) {
  color: #1D1921;
  background: #999999;
}
button.btn--success.loading::after,
a.btn.btn--success.loading::after {
  border-color: #1D1921;
}
button.btn--success-inverted,
a.btn.btn--success-inverted {
  background: transparent;
  color: #A1C83F;
  border-bottom: none;
  box-shadow: none;
}
button.btn--success-inverted:hover:not(:disabled, .disabled, .active),
a.btn.btn--success-inverted:hover:not(:disabled, .disabled, .active) {
  color: #1D1921;
  background: #bed97a;
}
button.btn--success-inverted:disabled:not(.app-button--active, .radio__button--checked, .active), button.btn--success-inverted.disabled:not(.app-button--active, .radio__button--checked, .active),
a.btn.btn--success-inverted:disabled:not(.app-button--active, .radio__button--checked, .active),
a.btn.btn--success-inverted.disabled:not(.app-button--active, .radio__button--checked, .active) {
  color: #1D1921;
  background: #999999;
}
button.btn--success-inverted.loading::after,
a.btn.btn--success-inverted.loading::after {
  border-color: #1D1921;
}
button.btn--important,
a.btn.btn--important {
  background: #AE61A1;
  background: linear-gradient(120deg, #AE61A1 0%, #a25395 50%, #AE61A1 100%);
  color: #1D1921;
}
button.btn--important:hover:not(:disabled, .disabled, .radio__button--checked, .active),
a.btn.btn--important:hover:not(:disabled, .disabled, .radio__button--checked, .active) {
  background: #c894bf;
}
button.btn--important:disabled:not(.app-button--active, .radio__button--checked, .active), button.btn--important.disabled:not(.app-button--active, .radio__button--checked, .active),
a.btn.btn--important:disabled:not(.app-button--active, .radio__button--checked, .active),
a.btn.btn--important.disabled:not(.app-button--active, .radio__button--checked, .active) {
  color: #1D1921;
  background: #999999;
}
button.btn--important.loading::after,
a.btn.btn--important.loading::after {
  border-color: #1D1921;
}
button.btn--important-inverted,
a.btn.btn--important-inverted {
  background: transparent;
  color: #AE61A1;
  border-bottom: none;
  box-shadow: none;
}
button.btn--important-inverted:hover:not(:disabled, .disabled, .active),
a.btn.btn--important-inverted:hover:not(:disabled, .disabled, .active) {
  color: #1D1921;
  background: #c894bf;
}
button.btn--important-inverted:disabled:not(.app-button--active, .radio__button--checked, .active), button.btn--important-inverted.disabled:not(.app-button--active, .radio__button--checked, .active),
a.btn.btn--important-inverted:disabled:not(.app-button--active, .radio__button--checked, .active),
a.btn.btn--important-inverted.disabled:not(.app-button--active, .radio__button--checked, .active) {
  color: #1D1921;
  background: #999999;
}
button.btn--important-inverted.loading::after,
a.btn.btn--important-inverted.loading::after {
  border-color: #1D1921;
}
button.btn--link,
a.btn.btn--link {
  background: transparent;
  color: #e93a3a;
  border: none;
  box-shadow: none;
}
button.btn--link:hover:not(:disabled, .disabled, .active, .loading),
a.btn.btn--link:hover:not(:disabled, .disabled, .active, .loading) {
  color: #ee6868;
  background: transparent;
}
button.btn--link:disabled:not(.app-button--active, .radio__button--checked), button.btn--link.disabled:not(.app-button--active, .radio__button--checked),
a.btn.btn--link:disabled:not(.app-button--active, .radio__button--checked),
a.btn.btn--link.disabled:not(.app-button--active, .radio__button--checked) {
  color: #999999;
  background: transparent;
}
button.btn--link.loading::after,
a.btn.btn--link.loading::after {
  border-color: #1D1921;
}
button.btn--link-inverted,
a.btn.btn--link-inverted {
  background: transparent;
  color: #EFEFEF;
  border: none;
  box-shadow: none;
}
button.btn--link-inverted:hover:not(:disabled, .disabled, .active, .loading),
a.btn.btn--link-inverted:hover:not(:disabled, .disabled, .active, .loading) {
  color: white;
  background: transparent;
}
button.btn--link-inverted:disabled:not(.app-button--active, .radio__button--checked), button.btn--link-inverted.disabled:not(.app-button--active, .radio__button--checked),
a.btn.btn--link-inverted:disabled:not(.app-button--active, .radio__button--checked),
a.btn.btn--link-inverted.disabled:not(.app-button--active, .radio__button--checked) {
  color: #999999;
  background: transparent;
}
button.btn--link-inverted.loading::after,
a.btn.btn--link-inverted.loading::after {
  border-color: #1D1921;
}
button.btn--round,
a.btn.btn--round {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: solid 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
  margin: auto;
  color: #aa1313;
  font-size: 30px;
  text-shadow: 0 -1px 0px rgba(0, 0, 0, 0.6);
  background: linear-gradient(92.88deg, #e93a3a 9.16%, #ee6868 43.89%, #d81818 64.72%);
}
button.btn--round:disabled, button.btn--round.disabled,
a.btn.btn--round:disabled,
a.btn.btn--round.disabled {
  color: #999999;
  background: linear-gradient(92.88deg, #ccc 9.16%, #fff 43.89%, #ddd 64.72%);
  border-bottom-color: rgba(0, 0, 0, 0.2);
}
button.btn--header,
a.btn.btn--header {
  height: 50px;
  border-radius: 0;
  font-size: 16px;
}
@media (min-width: 480px) {
  button.btn--header,
  a.btn.btn--header {
    height: 60px;
    font-size: 20px;
  }
}
button.btn--header:disabled,
a.btn.btn--header:disabled {
  background: transparent;
  border-color: transparent;
  color: #1D1921;
}
button:focus, button:focus-within, button:active, button.active,
a.btn:focus,
a.btn:focus-within,
a.btn:active,
a.btn.active {
  z-index: 1;
  border-bottom-color: transparent;
}
button:disabled, button.disabled,
a.btn:disabled,
a.btn.disabled {
  cursor: not-allowed;
  border-bottom-color: transparent;
}

table {
  width: 100%;
  border-spacing: 0 8px;
}
table thead th {
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  padding: 0 4px;
  color: #1D1921;
}
@media (min-width: 481px) {
  table thead th {
    padding: 8px;
  }
}
table tbody tr {
  border-radius: 8px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2);
}
table tbody td {
  min-height: 64px;
}
table tbody td, table tbody th {
  position: relative;
  height: 64px;
  padding: 4px;
}
@media (min-width: 481px) {
  table tbody td, table tbody th {
    padding: 8px;
  }
}
table tbody td:first-child, table tbody th:first-child {
  border-radius: 8px 0 0 8px;
}
table tbody td:last-child, table tbody th:last-child {
  border-radius: 0 8px 8px 0;
}
table tbody td:first-child:last-child, table tbody th:first-child:last-child {
  border-radius: 8px;
}
table td.left, table th.left {
  text-align: left;
}
table td.center, table th.center {
  text-align: center;
}
table td.right, table th.right {
  text-align: right;
}
table tr.highlighted td, table tr.highlighted th {
  background: #ffffff;
}
table .indent th {
  padding-left: 16px;
}
table .indent th, table .indent td {
  background: rgba(2, 52, 2, 0.2);
}

.icon-prefix {
  margin-right: 8px;
}

.icon-suffix {
  margin-left: 8px;
}

@media (max-width: 320px) {
  .hide-on-mobile-xs {
    display: none;
  }
}

@media (max-width: 480px) {
  .hide-on-mobile {
    display: none;
  }
}

@media (max-width: 768px) {
  .hide-on-tablet {
    display: none;
  }
}

@media (min-width: 481px) {
  .hide-on-desktop {
    display: none;
  }
}

.app__bg {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  bottom: -100px;
  right: 0;
  opacity: 0.3;
  z-index: -1;
  animation: slide-down 1.5s;
}
.app__bg > img {
  display: block;
  height: 100%;
  width: auto;
}

.grecaptcha-badge {
  right: -100% !important;
  opacity: 0;
}

.copy-text {
  cursor: copy;
  color: #e93a3a;
}
.copy-text:hover, .copy-text:focus {
  color: #cb7037;
}

.submenu {
  display: flex;
  position: sticky;
  transform: translate(0);
  width: 100vw;
  z-index: 4;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.5);
}
@media (max-width: 768px) {
  .submenu > * {
    flex-direction: column;
  }
  .submenu > * > fa-icon {
    margin-bottom: 4px;
  }
  .submenu > * > span {
    font-size: 8px;
    letter-spacing: 0.1px;
  }
}
@media (min-width: 769px) {
  .submenu > * {
    padding: 16px;
  }
  .submenu > * > fa-icon {
    margin-right: 8px;
  }
}
.submenu .active, .submenu .active:hover {
  color: #003a00;
  background: #ffffff;
}
.submenu > * {
  border-radius: 0;
  margin: 0;
  background: #1D1921;
  background: linear-gradient(120deg, #1D1921 0%, #100e12 50%, #1D1921 100%);
  color: #EFEFEF;
}
.submenu > *:hover:not(:disabled, .disabled, .radio__button--checked, .active) {
  background: #433a4d;
}
.submenu > *:disabled:not(.app-button--active, .radio__button--checked, .active), .submenu > *.disabled:not(.app-button--active, .radio__button--checked, .active) {
  color: #1D1921;
  background: #999999;
}
.submenu > *.loading::after {
  border-color: #1D1921;
}
.submenu > *:not(:first-of-type, :last-of-type) {
  border-left: solid 1px rgba(0, 0, 0, 0.5);
  border-right: solid 1px rgba(0, 0, 0, 0.5);
}