$font-size-xs: 8px;
$font-size-sm: 11px;
$font-size-md: 14px;
$font-size-base: 16px;
$font-size-lg: 20px;
$font-size-xl: 30px;
$font-size-score: 50px;

$font-family-primary: 'Exo 2', Arial, sans-serif;
$font-family-secondary: monospace;

$font-weight-thin: 200;
$font-weight-normal: 400;
$font-weight-semibold: 500;
$font-weight-bold: 700;

$spacing-xs: 4px;
$spacing-sm: 8px;
$spacing-base: 16px;
$spacing-lg: 24px;
$spacing-xl: 32px;

$breakpoint-desktop-4k: 3000px;
$breakpoint-desktop-qhd: 1920px;
$breakpoint-desktop-hd: 1260px;
$breakpoint-desktop: 960px;
$breakpoint-tablet: 768px;
$breakpoint-mobile: 480px;
$breakpoint-mobile-xs: 320px;

$table-row-height: 64px;
$table-header-font-size: 12px;
$table-border-radius: 8px;

$fieldset-border-width: 3px;

$header-min-height: 66px;

$input-spacing: $spacing-sm;
$input-border-width: 2px;
$input-font-size: $font-size-lg;
$input-height: $input-font-size + (2 * $input-spacing) + (2 * $input-border-width) + 2;

$selected-option-size: 32px;
$selected-option-image-size: $selected-option-size - $input-spacing;

$checkbox-size: 26px;

$border-radius-xs: 2px;
$border-radius-sm: 4px;
$border-radius-md: 6px;
$border-radius: 8px;
$border-radius-lg: 10px;
$border-radius-xl: 20px;
$border-radius-max: 50%;

$button-border-width: 2px;
$button-size-sm: 24px;
$button-size-md: 32px;
$button-size: $input-height;
$button-size-lg: 52px;
$button-size-xl: 150px;

$scrollbar-size: $spacing-sm;
