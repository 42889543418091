@keyframes slide-up {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slide-down {
  0% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slide-out-right {
  0% {
    transform: translateX(0) rotateZ(0deg);
  }
  100% {
    transform: translateX(400px) rotateZ(45deg);
  }
}

@keyframes slide-out-left {
  0% {
    transform: translateX(0) rotateZ(0deg);
  }
  100% {
    transform: translateX(-400px) rotateZ(-45deg);
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes disappear {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes stamp {
  0% {
    transform: perspective(500px) translate3d(0, 0, 300px) rotate(-30deg);
  }
  100% {
    transform: perspective(500px) translate3d(0, 0, 0px) rotate(-30deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(1turn);
  }
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes blink {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}

@keyframes roll-down-previous {
  0% {
    opacity: 1;
    transform: translateY(var(--counter-size, -20px)) rotateX(0);
  }
  100% {
    opacity: 0.7;
    transform: translateY(0) rotateX(60deg);
  }
}

@keyframes roll-down-current {
  0% {
    opacity: 0.7;
    transform: translateY(var(--counter-size, -20px)) rotateX(-60deg);
  }
  100% {
    opacity: 1;
    transform: translateY(0) rotateX(0);
  }
}

@keyframes roll-down-next {
  0% {
    opacity: 0;
    transform: translateY(var(--counter-size, -20px)) rotateX(-90deg);
  }
  100% {
    opacity: 0.7;
    transform: translateY(0) rotateX(-60deg);
  }
}
